<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <Form
    v-slot="{ validate, values }"
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="accordion" id="accordionAnagraficaPersona">
      <form autocomplete="off">
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAnagraficaPersona-collapseOne"
              >
                Dati Anagrafici
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaPersona-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <!-- ---- label, name, placeholder-->
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Cognome*</label
                        >
                        <TextInput
                          name="cognome"
                          type="text"
                          :value="personaFromProps.cognome"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1">Nome*</label>
                        <TextInput
                          name="nome"
                          type="text"
                          :value="personaFromProps.nome"
                        />
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Data di nascita*
                        </label>
                        <Datepicker
                          v-model="personaFromProps.data_nascita"
                          name="data_nascita"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :format="format"
                          :maxDate="new Date()"
                        />
                        <Field
                          name="data_nascita"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.data_nascita"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_nascita"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Codice Fiscale*
                          <Popper
                            v-if="!personaFromProps.cf_ok"
                            arrow
                            :content="personaFromProps.ck_no_ok_avviso"
                            style="margin: -8px"
                          >
                            <i
                              class="bi bi-exclamation-triangle-fill text-danger"
                            ></i>
                          </Popper>
                        </label>
                        <TextInput
                          name="cod_fisc"
                          type="text"
                          :value="personaFromProps.cod_fisc"
                          @focusOut="checkCodFisc"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Matricola</label
                        >
                        <TextInput
                          name="id"
                          :value="personaFromProps.matricola"
                          type="text"
                          disabled
                        />
                      </div>
                      <div class="col-md-2">
                        <label class="fw-bold text-gray-600 p-1">Sesso*</label>
                        <SelectInput
                          :options="maschioFemminaLookup"
                          :value="personaFromProps.sesso"
                          name="sesso"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Cittadinanza*</label
                        >
                        <SelectInput
                          :options="statiCittadinanza"
                          :value="personaFromProps.cittadinanza"
                          name="cittadinanza"
                          @changeSelect="
                            personaFromProps.id_tipo_docum_straniero = null;
                            personaFromProps.permesso_Soggiorno = false;
                            personaFromProps.scadenza_permesso_soggiorno = null;
                          "
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Cittadinanza Sportiva
                        </label>
                        <SelectInput
                          :options="statiCittadinanza"
                          :value="personaFromProps.cittadinanza_sportiva"
                          name="cittadinanza_sportiva"
                          @changeSelect="
                            personaFromProps.cittadinanza_sportiva = $event;
                            personaFromProps.id_tipo_docum_straniero = null;
                            personaFromProps.permesso_Soggiorno = false;
                            personaFromProps.scadenza_permesso_soggiorno = null;
                          "
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Diversamente abile</label
                        ><br />
                        <input
                          class="form-check-input mt-1"
                          type="checkbox"
                          name="flexcheckboxDefault"
                          v-model="disabile"
                        />
                        <Field
                          type="hidden"
                          name="disabile"
                          v-model="disabile"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Transgender</label
                        ><br />
                        <input
                          class="form-check-input mt-1"
                          type="checkbox"
                          name="flexcheckboxDefault"
                          v-model="transgender"
                          @click="values.transgender_tipo = null"
                        />
                        <Field
                          type="hidden"
                          name="transgender"
                          v-model="transgender"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Tipologia</label
                        >
                        <SelectInput
                          :options="transgender_tipi"
                          :value="personaFromProps.transgender_tipo"
                          name="transgender_tipo"
                          :disabled="!transgender"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1">E-mail*</label>
                        <TextInput
                          name="email"
                          :value="personaFromProps.email"
                          type="email"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1">Skype</label>
                        <TextInput
                          name="skype"
                          :value="personaFromProps.skype"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingTwo"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseTwo"
                aria-expanded="false"
                aria-controls="accordionAnagraficaPersona-collapseTwo"
              >
                Luogo di nascita
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaPersona-headingTwo"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-2 mb-4">
                        <label class="fw-bold text-gray-600" for="italia"
                          >Italia</label
                        >
                        <Field
                          v-model="personaFromProps.straniero"
                          class="form-check-input mt-8"
                          type="radio"
                          name="straniero"
                          id="italia"
                          :value="0"
                          :checked="!personaFromProps.straniero ? true : false"
                          @click="
                            personaFromProps.straniero = 0;
                            personaFromProps.comune_nascita = 0;
                            personaFromProps.comune_nascita_label = '';
                            personaFromProps.stato_nascita = 0;
                            personaFromProps.localita_nascita = '';
                            values.localita_nascita = '';
                            values.stato_nascita = 0;
                          "
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Comune nascita</label
                        >

                        <Field
                          name="comune_nascita_label"
                          type="text"
                          placeholder="Digita almeno 3 caratteri..."
                          class="form-control"
                          v-model="personaFromProps.comune_nascita_label"
                          @input="
                            spliceComuniNascita();
                            setComuniNascita($event.target.value);
                          "
                          @focus="inputFocus = 'comuneN'"
                          @keyup.delete="
                            spliceComuniNascita();
                            personaFromProps.comune_nascita = 0;
                          "
                          :disabled="personaFromProps.straniero"
                        />
                        <Field
                          :disabled="personaFromProps.straniero"
                          name="comune_nascita"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.comune_nascita"
                          autocomplete="off"
                        />
                        <ListComuni
                          v-if="comuniNascita && inputFocus === 'comuneN'"
                          :list="comuniNascita"
                          @hideList="spliceComuniNascita"
                          @selectedElement="
                            personaFromProps.comune_nascita_label = $event.name;
                            personaFromProps.comune_nascita = $event.value;
                            spliceComuniNascita();
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_nascita"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 mb-4">
                        <label class="fw-bold text-gray-600" for="estero"
                          >Estero</label
                        >
                        <Field
                          v-model="personaFromProps.straniero"
                          class="form-check-input mt-8"
                          type="radio"
                          name="straniero"
                          id="estero"
                          :value="1"
                          :checked="personaFromProps.straniero"
                          @click="
                            personaFromProps.stato_nascita = 0;
                            personaFromProps.comune_nascita = 0;
                            personaFromProps.comune_nascita_label = '';
                            personaFromProps.localita_nascita = '';
                            values.localita_nascita = '';
                            values.stato_nascita = 0;
                          "
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Nazione nascita</label
                        >
                        <SelectInput
                          :options="statiNascita"
                          :value="personaFromProps.stato_nascita"
                          name="stato_nascita"
                          :disabled="!personaFromProps.straniero"
                        />
                        <Field
                          name="stato_nascita"
                          type="hidden"
                          class="form-control"
                          :value="personaFromProps.stato_nascita"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600">Località</label>
                        <Field
                          name="localita_nascita"
                          type="text"
                          placeholder=""
                          class="form-control"
                          :value="personaFromProps.localita_nascita"
                          :disabled="!personaFromProps.straniero"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="localita_nascita"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
              <ErrorMessage
                class="help-message"
                style="color: red"
                name="straniero"
              />
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingThree"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseThree"
                aria-expanded="false"
                aria-controls="accordionAnagraficaPersona-collapseThree"
              >
                Residenza
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaPersona-headingThree"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Nazione residenza</label
                        >
                        <SelectInput
                          :options="statiResidenza"
                          :value="personaFromProps.nazione_residenza"
                          name="nazione_residenza"
                          @changeSelect="
                            personaFromProps.nazione_residenza = $event;
                            changeSelectedNazione($event);
                            validate();
                            personaFromProps.prov_residenza = 0;
                            personaFromProps.comune_residenza = 0;
                            personaFromProps.comune_residenza_label = '';
                          "
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Provincia residenza</label
                        >
                        <SelectInput
                          :options="province"
                          :value="personaFromProps.prov_residenza.toString()"
                          name="prov_residenza"
                          :disabled="italiaResidenza ? false : true"
                          @changeSelect="
                            changeselectedProvinciaR($event);
                            comune_residenza_label = '';
                            comune_residenza = 0;
                            validate();
                          "
                        />
                        <Field
                          name="prov_residenza"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.prov_residenza"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Comune residenza</label
                        >

                        <Field
                          name="comune_residenza_label"
                          type="text"
                          placeholder="Digita almeno 3 caratteri..."
                          class="form-control"
                          v-model="personaFromProps.comune_residenza_label"
                          @input="
                            spliceComuniIstat();
                            setComuniIstat(
                              personaFromProps.comune_residenza_label,
                              personaFromProps.prov_residenza
                            );
                          "
                          @focus="inputFocus = 'comuneR'"
                          @keyup.delete="
                            spliceComuniIstat();
                            personaFromProps.comune_residenza = 0;
                          "
                          :disabled="
                            personaFromProps.nazione_residenza != 'IT' ||
                            personaFromProps.prov_residenza === 0
                          "
                        />
                        <Field
                          name="comune_residenza"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.comune_residenza"
                          autocomplete="off"
                        />
                        <ListComuni
                          v-if="comuniResidenza && inputFocus === 'comuneR'"
                          :list="comuniResidenza"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            personaFromProps.comune_residenza_label =
                              $event.name;
                            personaFromProps.comune_residenza = $event.value;
                            spliceComuniIstat();
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_residenza"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Indirizzo*</label
                        >
                        <TextInput
                          name="indirizzo_residenza"
                          :value="personaFromProps.indirizzo_residenza"
                          type="text"
                        />
                      </div>
                      <div class="col-md-2">
                        <label class="fw-bold text-gray-600 p-1">C.A.P.*</label>
                        <TextInput
                          name="cap_residenza"
                          :value="personaFromProps.cap_residenza"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Località</label
                        >
                        <TextInput
                          name="localita_residenza"
                          :value="personaFromProps.localita_residenza"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="w-bold text-gray-600 p-1"
                          >Telefono 1</label
                        >
                        <TextInput
                          name="telefono"
                          :value="personaFromProps.telefono"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Telefono 2</label
                        >
                        <TextInput
                          name="telefono2"
                          :value="personaFromProps.telefono2"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">Fax</label>
                        <TextInput
                          name="fax"
                          :value="personaFromProps.fax"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Cellulare
                        </label>
                        <TextInput
                          name="mobile"
                          :value="personaFromProps.mobile"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingFour"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseFour"
                aria-expanded="false"
                aria-controls="accordionAnagraficaPersona-collapseFour"
              >
                Documento d'identità
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaPersona-headingFour"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600 p-1">Tipo</label>
                        <SelectInput
                          :options="tipiDocIdentita"
                          :value="personaFromProps.tipo_doc"
                          name="tipo_doc"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">Numero</label>
                        <TextInput
                          name="numero_doc"
                          :value="personaFromProps.numero_doc"
                          type="text"
                        />
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Data scadenza</label
                        >
                        <Datepicker
                          v-model="personaFromProps.data_doc"
                          name="data_doc"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :format="format"
                          :minDate="new Date()"
                        />
                        <Field
                          name="data_doc"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.data_doc"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_doc"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Rilasciato da</label
                        >

                        <SelectInput
                          :options="tipiDocRilascio"
                          :value="personaFromProps.rilascio_doc"
                          name="rilascio_doc"
                          @changeSelect="changeSelectedDoc($event)"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Provincia rilascio</label
                        >
                        <SelectInput
                          :options="province"
                          :value="personaFromProps.prov_doc"
                          name="prov_doc"
                          :disabled="selectedDoc ? false : true"
                          @changeSelect="changeselectedProvinciaD($event)"
                        />
                        <Field
                          name="prov_doc"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.prov_doc"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Comune rilascio</label
                        >
                        <Field
                          name="comune_doc_label"
                          type="text"
                          placeholder="Digita almeno 3 caratteri..."
                          class="form-control"
                          v-model="personaFromProps.comune_doc_label"
                          @input="
                            spliceComuniIstat();
                            setComuniIstat(
                              personaFromProps.comune_doc_label,
                              personaFromProps.prov_doc
                            );
                          "
                          @focus="inputFocus = 'comuneD'"
                          @keyup.delete="
                            spliceComuniIstat();
                            personaFromProps.comune_doc = 0;
                          "
                          :disabled="!personaFromProps.prov_doc"
                        />
                        <Field
                          name="comune_doc"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.comune_doc"
                          autocomplete="off"
                        />
                        <ListComuni
                          v-if="comuniResidenza && inputFocus === 'comuneD'"
                          :list="comuniResidenza"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            personaFromProps.comune_doc_label = $event.name;
                            personaFromProps.comune_doc = $event.value;
                            spliceComuniIstat();
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_doc"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="fw-bold text-gray-600 p-1">Note</label>
                        <TextInput
                          name="note_doc"
                          :value="personaFromProps.note_doc"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row" v-if="values.cittadinanza != 0">
                      <div class="col-md-4 d-flex flex-column">
                        <label class="fw-bold text-gray-600"
                          >Motivazione Permesso</label
                        >
                        <div class="mt-1">
                          <SelectInputEvento
                            :options="straniero_tipo_documenti"
                            :value="personaFromProps.id_tipo_docum_straniero"
                            :cittadinanza_sportiva_persona="
                              personaFromProps.cittadinanza_sportiva
                            "
                            name="permesso_soggiorno"
                            @changeSelect="
                              changeSelectedTipoDocStraniero($event)
                            "
                          />
                          <input
                            class="form-check-input"
                            type="hidden"
                            :value="personaFromProps.permesso_soggiorno"
                            id="flexCheckDefault"
                            @click="
                              personaFromProps.permesso_soggiorno =
                                !personaFromProps.permesso_soggiorno
                            "
                            :checked="personaFromProps.permesso_soggiorno"
                            :disabled="
                              values.cittadinanza === 0 ||
                              !isEnabled('fnAnagraficaPersonePermessoSoggiorno')
                            "
                          />
                          <Field
                            name="permesso_Soggiorno"
                            type="hidden"
                            class="form-control"
                            v-model="personaFromProps.permesso_soggiorno"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="permesso_Soggiorno"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Data Scadenza</label
                        >
                        <Datepicker
                          v-model="personaFromProps.scadenza_permesso_soggiorno"
                          name="data_Scadenza_Permesso_Soggiorno"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :minDate="new Date()"
                          :disabled="
                            disableChangeDate ||
                            !personaFromProps.view_scadenza_permesso_soggiorno
                          "
                        />
                        <Field
                          name="data_Scadenza_Permesso_Soggiorno"
                          type="hidden"
                          class="form-control"
                          v-model="personaFromProps.scadenza_permesso_soggiorno"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Scadenza_Permesso_Soggiorno"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingFour"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseFive"
                aria-expanded="false"
                aria-controls="accordionAnagraficaPersona-collapseFive"
              >
                Coordinate bancarie
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaPersona-headingFive"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">Banca 1</label>
                        <TextInput
                          name="banca"
                          :value="personaFromProps.banca"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Agenzia 1</label
                        >
                        <TextInput
                          name="indirizzo_banca"
                          :value="personaFromProps.indirizzo_banca"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">IBAN 1</label>
                        <TextInput
                          name="iban"
                          :value="personaFromProps.iban"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">Banca 2</label>

                        <TextInput
                          name="banca_2"
                          :value="personaFromProps.banca_2"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Agenzia 2</label
                        >

                        <TextInput
                          name="indirizzo_banca_2"
                          :value="personaFromProps.indirizzo_banca_2"
                          type="text"
                        />
                      </div>

                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1">IBAN 2</label>

                        <TextInput
                          name="iban_2"
                          :value="personaFromProps.iban_2"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaPersona-headingFour"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaPersona-collapseSix"
                aria-expanded="false"
                aria-controls="accordionAnagraficaPersona-collapseSix"
              >
                Altre informazioni
              </button>
            </h6>
            <div
              id="accordionAnagraficaPersona-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaPersona-headingSix"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600 p-1">Taglia</label>
                        <SelectInput
                          :options="taglie"
                          :value="personaFromProps.taglia"
                          name="taglia"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Peso (Kg)</label
                        >
                        <TextInput
                          name="peso"
                          :value="personaFromProps.peso"
                          type="text"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600 p-1"
                          >Altezza (cm)</label
                        >
                        <TextInput
                          name="altezza"
                          :value="personaFromProps.altezza"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-md-4">
                      <label class="fw-bold text-gray-600 p-1"
                        >Cognome materno</label
                      >
                      <TextInput name="cognome-materno" type="text" />
                    </div> -->
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Titolo di studio</label
                        >
                        <SelectInput
                          :options="titoliStudio"
                          :value="personaFromProps.id_titolo_studio"
                          name="id_titolo_studio"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Lingue conosciute</label
                        >
                        <SelectInput
                          :options="lingueParlate"
                          :value="personaFromProps.id_lingue1"
                          name="id_lingue1"
                          @changeSelect="changeLingua1($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          :value="personaFromProps.id_lingue2"
                          name="id_lingue2"
                          :disabled="lingue2disabled ? true : false"
                          @changeSelect="changeLingua2($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          :value="personaFromProps.id_lingue3"
                          name="id_lingue3"
                          :disabled="lingue3disabled ? true : false"
                          @changeSelect="changeLingua3($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          :value="personaFromProps.id_lingue4"
                          name="id_lingue4"
                          :disabled="lingue4disabled ? true : false"
                          @changeSelect="changeLingua4($event)"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Targhe auto
                        </label>
                        <TextInput
                          name="targa1"
                          :value="personaFromProps.targa1"
                          type="text"
                        />
                      </div>
                      <div class="col-md-4 no-label">
                        <TextInput
                          name="targa2"
                          :value="personaFromProps.targa2"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!--end::details View-->
    <div v-if="!readOnly" class="text-end">
      <button
        type="submit"
        class="badge bg-secondary rounded blueFit fs-5"
        @click="submit"
      >
        Salva Modifiche
      </button>
    </div>
  </Form>
</template>

<script>
const debugPrint = false;
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
  onUpdated,
  watch,
} from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

// componenti standard
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

// componenti
import TextInput from "../../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../../components/components-fit/utility/inputs/SelectInput_v01.vue";
import SelectInputEvento from "../../../components/components-fit/utility/inputs/SelectInputEvento.vue";
import ListComuni from "../../../components/components-fit/utility/ListComuni.vue";

// gestione stato
import { useStore } from "vuex";

// richieste dati
import { updatePersona } from "../../../requests/personaRequests";

// validazione e alert
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

// check
import { checkCodFisc } from "../../../requests/checkCodiceFiscale";

// css
import "vue3-date-time-picker/dist/main.css";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useRoute } from "vue-router";
import isEnabled from "@/composables/isEnabled.js";
import { useRouter } from "vue-router";
import Popper from "vue3-popper";

// componente
export default defineComponent({
  name: "anagrafica-persona",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    TextInput,
    SelectInput,
    ListComuni,
    Loading,
    SelectInputEvento,
    Popper,
  },
  props: ["persona", "campiLocked"],
  emits: ["fromPersone", "fromSocieta", "refreshPersona"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const route = useRoute();
    const fromPersone = ref(route.params.fromPersone);
    const fromSocieta = ref(route.params.fromSocieta);
    const id_societa = ref(route.params.id_societa);

    if (fromPersone.value) emit("fromPersone");
    if (fromSocieta.value && id_societa.value)
      emit("fromSocieta", id_societa.value);
    // inizio
    const router = useRouter();

    onMounted(() => {
      if (router.currentRoute._value.name == "anagrafica-persona") {
        setCurrentPageBreadcrumbs("Anagrafica Persona", [
          "Lista Persone",
          "Dettaglio Persona",
        ]);
      }
    });

    // INIZIALIZZAZIONI
    const personaFromProps = ref(props.persona);

    const inputFocus = ref("");

    const disabile = ref(props.persona.disabile);
    const transgender = ref(props.persona.transgender);
    const permessoSoggiorno = ref(props.persona.scadenza_permesso_soggiorno);

    //lingue
    const lingue2disabled = ref(
      personaFromProps.value.id_lingue1 ? false : true
    );
    const lingue3disabled = ref(
      personaFromProps.value.id_lingue2 ? false : true
    );
    const lingue4disabled = ref(
      personaFromProps.value.id_lingue3 ? false : true
    );

    const italiaResidenza = ref(
      personaFromProps.value.nazione_residenza === "IT" ? true : false
    );

    const selectedDoc = ref(
      personaFromProps.value.prov_doc !== null ? true : false
    );

    const selectedProvinciaD = ref(true);

    const selectedProvinciaR = ref(true);

    // lookups - store GETTERS
    const titoliStudio = computed(() =>
      store.getters.getStateFromName("titoli_studio")
    );
    const lingueParlate = computed(() =>
      store.getters.getStateFromName("lingue_parlate")
    );
    const taglie = computed(() => store.getters.getStateFromName("taglie"));
    const statiCittadinanza = computed(() =>
      store.getters.getStateFromName("stati_cittadinanza")
    );
    const statiNascita = computed(() =>
      store.getters.getStateFromName("stati_nascita")
    );
    const statiResidenza = computed(() =>
      store.getters.getStateFromName("stati_residenza")
    );
    const tipiDocIdentita = computed(() =>
      store.getters.getStateFromName("tipo_docum_identita")
    );
    const tipiDocRilascio = computed(() =>
      store.getters.getStateFromName("tipo_docum_rilascio")
    );
    const straniero_tipo_documenti = computed(() =>
      store.getters.getStateFromName("straniero_tipo_documenti")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );

    // lookups chiamate - store ACTIONS
    const keys = ref("ts|lp|t");
    if (!titoliStudio.value || !lingueParlate.value || !taglie.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const keys2 = ref("sn|sc|sr|ip");
    if (
      !statiNascita.value ||
      !statiCittadinanza.value ||
      !statiResidenza.value ||
      !province.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys2.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const keys3 = ref("tdi|tdr|ptds");
    if (!tipiDocIdentita.value || !tipiDocRilascio.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys3.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    // altre store ACTIONS
    const setComuniNascita = (string) => {
      if (string.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            nomec: string,
          },
          apiLink: globalApi.COMUNI_NASCITA_LIST,
        });
      }
    };

    const setComuniIstat = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    // FUNZIONI esterne - store MUTATIONS
    const spliceComuniNascita = () => {
      store.commit("spliceArrayFromName", "comuni_nascita");
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    // FUNZIONI nella pagina
    const changeSelectedNazione = (nazione) => {
      if (nazione != "IT") {
        italiaResidenza.value = false;
        personaFromProps.value.prov_residenza = 0;
        personaFromProps.value.nazione_residenza = nazione;
      } else {
        italiaResidenza.value = true;
      }
    };

    const changeSelectedDoc = (doc) => {
      if (doc === "-") {
        selectedDoc.value = false;
        personaFromProps.value.prov_doc = null;
      } else {
        selectedDoc.value = true;
      }
    };

    const changeselectedProvinciaR = (event) => {
      if (event === undefined || event === null || event === "-") {
        personaFromProps.value.prov_residenza = 0;
        selectedProvinciaR.value = false;
        personaFromProps.value.comune_residenza_label = "";
      } else if (event != personaFromProps.value.prov_residenza) {
        selectedProvinciaR.value = true;
        personaFromProps.value.prov_residenza = parseInt(event);
        personaFromProps.value.comune_residenza_label = "";
        personaFromProps.value.comune_residenza = 0;
      } else {
        selectedProvinciaR.value = true;
      }
    };

    const changeselectedProvinciaD = (event) => {
      if (event === undefined || event === null || event === "-") {
        personaFromProps.value.prov_doc = null;
        selectedProvinciaD.value = false;
        personaFromProps.value.comune_doc_label = "";
      } else if (event != personaFromProps.value.prov_doc) {
        selectedProvinciaD.value = true;
        personaFromProps.value.prov_doc = parseInt(event);
        personaFromProps.value.comune_doc_label = "";
      } else {
        selectedProvinciaD.value = true;
      }
    };

    const changeLingua1 = (e) => {
      personaFromProps.value.id_lingue1 = e;
      if (e === null) {
        personaFromProps.value.id_lingue2 = null;
        personaFromProps.value.id_lingue3 = null;
        personaFromProps.value.id_lingue4 = null;
        lingue2disabled.value = true;
        lingue3disabled.value = true;
        lingue4disabled.value = true;
      } else {
        lingue2disabled.value = false;
      }
    };

    const changeLingua2 = (e) => {
      personaFromProps.value.id_lingue2 = e;
      if (e === null) {
        personaFromProps.value.id_lingue3 = null;
        personaFromProps.value.id_lingue4 = null;
        lingue3disabled.value = true;
        lingue4disabled.value = true;
      } else {
        lingue3disabled.value = false;
      }
    };

    const changeLingua3 = (e) => {
      personaFromProps.value.id_lingue3 = e;
      if (e === null) {
        personaFromProps.value.id_lingue4 = null;
        lingue4disabled.value = true;
      } else {
        lingue4disabled.value = false;
      }
    };

    const changeLingua4 = (e) => {
      personaFromProps.value.id_lingue4 = e;
    };

    const changeDataPermesso = () => {
      personaFromProps.value.data_Scadenza_Permesso_Soggiorno = null;
    };

    const changeSelectedTipoDocStraniero = (e) => {
      console.log(e);
      if (e.id) {
        personaFromProps.value.permesso_soggiorno = true;
        personaFromProps.value.id_tipo_docum_straniero = e.id;
        personaFromProps.value.view_scadenza_permesso_soggiorno = e.scadenza;
        if (!e.scadenza) {
          personaFromProps.value.scadenza_permesso_soggiorno = null;
        }
      } else {
        personaFromProps.value.permesso_soggiorno = false;
        personaFromProps.value.id_tipo_docum_straniero = null;
        personaFromProps.value.view_scadenza_permesso_soggiorno = null;
        personaFromProps.value.scadenza_permesso_soggiorno = null;
      }
    };

    // changeSelectedTipoDocStraniero(
    //   personaFromProps.value.id_tipo_docum_straniero
    // );

    // EVENTI INIZIALI

    // form Validation
    const schema = yup.object().shape({
      nome: yup.string().required("Obbligatorio"),
      cognome: yup.string().required("Obbligatorio"),
      sesso: yup.string().oneOf(["M", "F"], "Obbligatorio"),
      cod_fisc: yup
        .string()
        .test(
          "checkCodFisc",
          "Inserire un codice fiscale valido",
          async function (value) {
            if (
              value.length === 16 &&
              value != personaFromProps.value.cod_fisc
            ) {
              const isValidCodFisc = await checkCodFisc(
                value,
                globalApi.COD_FISC_PERSONE
              );
              return isValidCodFisc;
            } else if (value === personaFromProps.value.cod_fisc) return true;
            else {
              return false;
            }
          }
        ),
      email: yup
        .string()
        .email("Inserire un email valida")
        .required("Obbligatorio"),
      data_nascita: yup.string().required("Obbligatorio"),
      skype: yup.string(),
      cittadinanza: yup.string().required("Obbligatorio"),
      data_doc: yup.string().nullable(),
      matricola: yup.number(),
      straniero: yup
        .number("Obbligatorio")
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      stato_nascita: yup
        .number()
        .nullable()
        .typeError("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("straniero", {
          is: (straniero) => {
            return straniero === 1;
          },
          then: yup
            .number()
            .required("Obbligatorio")
            .typeError("Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      comune_nascita: yup
        .number()
        .nullable()
        .when("straniero", {
          is: (straniero) => {
            return straniero === 0;
          },
          then: yup
            .number()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Obbligatorio"),
          otherwise: yup.number().nullable(),
        }),
      //residenza
      nazione_residenza: yup
        .string()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      prov_residenza: yup
        .number()
        .nullable()
        .when("nazione_residenza", {
          is: (nazione_residenza) => {
            return nazione_residenza === "IT";
          },
          then: yup
            .number()
            .nullable()
            .moreThan(0, "Obbligatorio")
            .required("Obbligatorio")
            .typeError("Obbligatorio"),
        }),
      comune_residenza: yup
        .number()
        .nullable()
        .when("prov_residenza", {
          is: (prov_residenza) => {
            return prov_residenza > 0;
          },
          then: yup
            .number()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Riseleziona un comune dalla lista dinamica..."),
        }),
      indirizzo_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza !== null;
        },
        then: yup.string().required("Obbligatorio"),
      }),
      cap_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      localita_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza !== "IT";
        },
        then: yup.string().required("Obbligatorio"),
      }),
      permesso_Soggiorno: yup
        .boolean()
        .nullable()
        .transform((value) => (value === "" ? false : value)),
      data_Scadenza_Permesso_Soggiorno: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value))
        .when("permesso_Soggiorno", {
          is: (permesso_Soggiorno) => {
            return (
              permesso_Soggiorno &&
              personaFromProps.value.view_scadenza_permesso_soggiorno
            );
          },
          then: yup
            .string()
            .nullable()
            .required("Obbligatorio")
            .transform((value) => (value === "" ? null : value)),
          otherwhise: yup
            .string()
            .nullable()
            .transform((value) => (value === "" ? null : value)),
        }),
    });

    const isLoading = ref(false);

    // invio form
    const onSubmit = (values) => {
      isLoading.value = true;
      values = {
        ...values,
        stato_nascita: values.stato_nascita === null ? 0 : values.stato_nascita,
        straniero: personaFromProps.value.straniero,
        id_tipo_docum_straniero: personaFromProps.value.id_tipo_docum_straniero,
        tipo_nascita: props.persona.tipo_nascita,
        email_pec: props.persona.email_pec,
        deceduto: props.persona.deceduto,
        id: props.persona.id,
        data_Scadenza_Permesso_Soggiorno:
          values.permesso_soggiorno &&
          !personaFromProps.value.view_scadenza_permesso_soggiorno
            ? "2099-12-31"
            : values.data_Scadenza_Permesso_Soggiorno,
      };
      updatePersona(values).then((res) => {
        isLoading.value = false;
        if (res.status != 200) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          return;
        }
        alertSuccess("Persona modificata con successo");
        emit("refreshPersona");
      });
    };

    // form non valido
    const onInvalidSubmit = (v) => {
      let strMsg = "Compila i campi obbligatori";

      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };
    watch(straniero_tipo_documenti, () => {
      straniero_tipo_documenti.value.forEach((element) => {
        console.log(
          "straniero_tipo_documenti",
          element.id,
          personaFromProps.value
        );
        if (element.id == personaFromProps.value.id_tipo_docum_straniero) {
          changeSelectedTipoDocStraniero(element);
        }
      });
    });
    return {
      // campi e variabili pagina
      schema,
      inputFocus,
      personaFromProps,
      italiaResidenza,
      selectedProvinciaR,
      selectedProvinciaD,
      selectedDoc,
      lingue2disabled,
      lingue3disabled,
      lingue4disabled,
      // liste
      statiCittadinanza,
      statiNascita,
      statiResidenza,
      tipiDocIdentita,
      tipiDocRilascio,
      province,
      taglie,
      titoliStudio,
      lingueParlate,
      // liste old style
      maschioFemminaLookup: computed(() => store.getters.maschioFemminaLookup),
      comuniNascita: computed(() =>
        store.getters.getStateFromName("comuni_nascita")
      ),
      comuniResidenza: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      // funzionalità
      changeDataPermesso,
      format,
      changeLingua1,
      changeLingua2,
      changeLingua3,
      changeLingua4,
      changeSelectedDoc,
      changeSelectedNazione,
      changeselectedProvinciaR,
      changeselectedProvinciaD,
      setComuniNascita,
      setComuniIstat,
      spliceComuniNascita,
      spliceComuniIstat,
      checkCodFisc,
      onSubmit,
      onInvalidSubmit,
      isLoading,
      disabile,
      transgender,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      permessoSoggiorno,
      transgender_tipi: [
        { id: "MF_O", name: "MF operato" },
        { id: "MF_NO", name: "MF non operato" },
        { id: "FM", name: "FM" },
      ],
      straniero_tipo_documenti,
      changeSelectedTipoDocStraniero,
    };
  },
  computed: {
    disableChangeDate() {
      return this.isEnabled("fnAnagraficaPersonePermessoSoggiorno")
        ? false
        : new Date() <= new Date(this.permessoSoggiorno)
        ? true
        : false;
    },
  },
});
</script>
<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
